export default [
    {
        path: "/tools/experience/:experience_link_id",
        name: "ToolsExpPage",
        meta: {
          requiresAuth: false,
        },
    
        component: () =>
          import(/* webpackChunkName: "toolsexppage" */ "@/views/Experiences/ToolsDetail.vue"),
      },
  ];