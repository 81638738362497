<template>
  <div>
    <div
      class="w-2/3 relative left-1/2 transform -translate-x-1/2 md:top-10 hidden md:block"
    >
      <lottie-animation
        path="lotties/404.json"
        :loop="true"
        :autoPlay="true"
        :speed="1"
        :height="640"
      />
      <p class="text-3xl text-center text-black">
        Oops! The page you are looking for is not found!
      </p>
    </div>
    <div
      class="w-full relative left-1/2 transform -translate-x-1/2 top-20 block md:hidden"
    >
      <lottie-animation
        path="lotties/404.json"
        :loop="true"
        :autoPlay="true"
        :speed="1"
        :height="300"
      />
      <p class="mx-8 text-xl text-center text-black">
        Oops! The page you are looking for is not found!
      </p>
    </div>
  </div>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  components: { LottieAnimation },
};
</script>

<style scoped></style>
