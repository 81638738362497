export default [
  {
    path: "/demos/catalog",
    name: "OpenDemosCatalog",
    component: () =>
      import(
        /* webpackChunkName: "opendemoscatalog" */ "@/views/Demo/Catalog.vue"
      ),
  },
  {
    path: "/demos/welcome-video",
    name: "OpenEventWelcomeVideo",
    component: () =>
      import(
        /* webpackChunkName: "openeventwelcomevideo" */ "@/views/Demo/WelcomeVideo.vue"
      ),
  },
  {
    path: "/demos/evp",
    name: "OpenEventEVP",
    component: () =>
      import(
        /* webpackChunkName: "openeventevp" */ "@/views/Demo/EVP.vue"
      ),
  },
  {
    path: "/demos/birthday",
    name: "OpenEventBirthday",
    component: () =>
      import(
        /* webpackChunkName: "openeventbirthday" */ "@/views/Demo/Birthday.vue"
      ),
  },
  {
    path: "/demos/induction-video",
    name: "OpenEventInductionVideo",
    component: () =>
      import(
        /* webpackChunkName: "openeventinductionvideo" */ "@/views/Demo/InductionVideo.vue"
      ),
  },
  {
    path: "/demos/nha",
    name: "OpenEventNHA",
    component: () =>
      import(
        /* webpackChunkName: "openeventnha" */ "@/views/Demo/NHA.vue"
      ),
  },
  {
    path: "/demos/document-library",
    name: "OpenEventDocumentLibrary",
    component: () =>
      import(
        /* webpackChunkName: "openeventdocumentlibrary" */ "@/views/Demo/DocumentLibrary.vue"
      ),
  },
  {
    path: "/demos/document/:id",
    name: "OpenEventDocumentViewer",
    component: () =>
      import(
        /* webpackChunkName: "openeventdocumentviewer" */ "@/views/Demo/DocumentViewer.vue"
      ),
  },
  {
    path: "/demos/welcome360",
    name: "OpenEventWelcome360",
    component: () =>
      import(
        /* webpackChunkName: "openeventwelcome360" */ "@/views/Demo/Welcome360.vue"
      ),
  },
  {
    path: "/demos/scavenger-hunt",
    name: "OpenEventScavenger",
    component: () =>
      import(
        /* webpackChunkName: "openeventscavengerhunt" */ "@/views/Demo/ScavengerHunt.vue"
      ),
  },
  {
    path: "/demos/ttal",
    name: "OpenEventTTAL",
    component: () =>
      import(
        /* webpackChunkName: "openeventttal" */ "@/views/Demo/TTAL.vue"
      ),
  },
  {
    path: "/demos/gtm",
    name: "OpenEventGTM",
    component: () =>
      import(
        /* webpackChunkName: "openevengtm" */ "@/views/Demo/GTM.vue"
      ),
  },
  {
    path: "/demos/pulse-one-week",
    name: "OpenEventPulseOneWeek",
    component: () =>
      import(
        /* webpackChunkName: "openevenpulseoneweek" */ "@/views/Demo/PulseOneWeek.vue"
      ),
  },
  {
    path: "/demos/scratch-card",
    name: "OpenEventScratchCard",
    component: () =>
      import(
        /* webpackChunkName: "openeventscratchcard" */ "@/views/Demo/ScratchCard.vue"
      ),
  },
  {
    path: "/demos/spin-the-wheel",
    name: "OpenEventSpinTheWheel",
    component: () =>
      import(
        /* webpackChunkName: "openeventspinthewheel" */ "@/views/Demo/SpinTheWheel.vue"
      ),
  },
  {
    path: "/demos/jigsaw",
    name: "OpenEventJigsaw",
    component: () =>
      import(
        /* webpackChunkName: "openeventjigsaw" */ "@/views/Demo/Jigsaw.vue"
      ),
  },
];
