import { createStore } from "vuex";
import Auth from "./modules/Auth";
import Profile from "./modules/Profile";
import State from "./modules/State";

export default createStore({
  state: {
    showNavbar: true,
  },
  getters: {
    showNavbar(state) {
      return state.showNavbar;
    },
  },
  mutations: {
    setShowNavbar(state, data) {
      state.showNavbar = data;
    },
  },
  modules: { Auth, Profile, State },
});
