export default [
    {
      path: "/:company/experience/:experience_link_id",
      name: "ExperienceDetailPage",
      meta: {
        requiresAuth: true,
      },
  
      component: () =>
        import(/* webpackChunkName: "experiencedetailpage" */ "@/views/Experiences/Detail.vue"),
    },
    {
      path: "/:company/experience/documentviewer/:experience_link_id/:document_id",
      name: "DocumentViewer",
      component: () =>
        import(
          /* webpackChunkName: "obk-experience-document" */ "@/views/Experiences/DocumentViewer.vue"
        ),
      meta: {
        requiresAuth: true,
      },
      props: true,
    },
  ];
  