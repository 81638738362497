import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import TokenService from "@/common/token.service";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  state: {
    user: null,
    userType: null,
    token: null,
    refreshToken: null,
    isAuthenticated: !!TokenService.getToken("token"),
    companySlug: TokenService.getToken("company"),
    companyLogo: TokenService.getToken("company_logo"),
    maintenanceError: null,
    userSrc: !!TokenService.getToken("usersrc"),
  },
  getters: {
    user(state) {
      return state.user;
    },
    token(state) {
      return {
        token: state.token,
        refreshToken: state.refreshToken,
      };
    },
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    companySlug(state) {
      return state.companySlug;
    },
    companyLogo(state) {
      return state.companyLogo;
    },
    maintenanceError(state) {
      return state.maintenanceError;
    },
    userType(state) {
      return state.userType;
    },
  },
  mutations: {
    setAuth(state, data) {
      state.user = data.payload;
      state.token = data.token;
      state.companySlug = data.company;
      state.refreshToken = data.refresh_token;
      state.isAuthenticated = true;

      TokenService.saveToken("token", state.token);
      TokenService.saveToken("company", state.companySlug);

      if (data.payload.candidate_id) {
        TokenService.saveToken("type", "candidate");
      } else {
        TokenService.saveToken("type", "employee");
      }
    },
    purgeAuth(state) {
      state.user = null;
      state.token = null;
      state.refreshToken = null;
      state.isAuthenticated = false;

      TokenService.destroyToken("token");
      TokenService.destroyToken("company");
      TokenService.destroyToken("type");
    },
    setMaintenanceError(state, data) {
      state.maintenanceError = data;
    },
    setUserType(state, data) {
      state.userType = data;
    },
  },
  actions: {
    checkAuth({ commit, dispatch, rootGetters }) {
      if (TokenService.getToken("token")) {
        ApiService.setHeader();
        // API Call to get user details without Login
        if (!rootGetters.profile) {
          dispatch("getProfile");
        }

        if (!rootGetters.userType) {
          commit("setUserType", TokenService.getToken("type"));
        }
      } else {
        commit("purgeAuth");
      }
    },

    validateOtp({ commit }, params) {
      return new Promise((resolve, reject) => {
        ApiService.post(apiResource.auth.validateOtp, params)
          .then(({ data }) => {
            commit("setAuth", data.data);
            resolve(data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    signOut({ commit }) {
      commit("purgeAuth");
    },

    signOutLocal({ commit }) {
      commit("purgeAuth");
    },

    setError({ commit }, payload) {
      commit("setMaintenanceError", payload);
      toast.error(payload, { timeout: false });
    },
  },
};
