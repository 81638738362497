export const config = {
  url: process.env.VUE_APP_API_URL,
  env: process.env.NODE_ENV,
};

export const apiResource = {
  marketing: {
    countries: config.url + "/v2/marketing/get-countries",
    bookADemo: config.url + "/v2/marketing/book-demo",
    getInTouch: config.url + "/v2/marketing/get-in-touch",
  },
  auth: {
    checkCompanySlug: "/v2/auth/check-comp-slug",
    validateEmail: "/v2/auth/validate-email",
    validateOtp: "/v2/auth/validate-otp",
    resendOtp: "/v2/auth/resend-otp",
  },
  user: {
    getProfile: "/v2/user/get-profile",
  },
  homePage: {
    getDetails: "/v2/home-page/get-details",
  },
  experience: {
    getDetails: "/v2/experience/get-details",
    getToolsDetails: "/v2/tools-experience/get-details",
    getDocumentDetails: "/v2/document/get-details",
    getAssetsForQuestion: "/v2/pulse-1-week/get-assets-for-question",
    getNHAAnimations: "/v2/nha/get-animations",
    searchGIPHY: "/v2/nha/search-giphy",
    feedback: "/v2/feedback/add"
  },
  obk: {
    saveManagerNPSSurvey: "/v2/manager-nps/save-survey",
    savePulse1Week: "/v2/pulse-1-week/save-survey",
    saveCandidateExpSurvey: "/v2/candidate-exp-survey/save-survey",
    addGTMResult: "/v2/gtm/add-result",
    addTTALAnswer: "/v2/ttal/add-answer",
    saveScavengerHuntScore: "/v2/scavenger-hunt/save-score",
    submitNHA: "/v2/nha/submit",
    markExpAsComplete: "/v2/experience/mark-as-complete"
  },
  demo: {
    getDetails: "/sample-exps/get",
    addFeedback: "/sample-exps/feedback",
    getAssetsForPulseQuestion: "/sample-exps/pulse/get-assets-for-question",
  },
  events: {
    getSHRMExpDetails: "/events/shrm/get-exp-details"
  }
};
