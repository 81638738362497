export default [
  {
    path: "/umwelt/demos/catalog",
    name: "umweltDemosCatalog",
    component: () =>
      import(
        /* webpackChunkName: "umweltdemoscatalog" */ "@/views/UmweltDemo/Catalog.vue"
      ),
  },
  {
    path: "/umwelt/demos/welcome-video",
    name: "umweltEventWelcomeVideo",
    component: () =>
      import(
        /* webpackChunkName: "umwelteventwelcomevideo" */ "@/views/UmweltDemo/WelcomeVideo.vue"
      ),
  },
  {
    path: "/umwelt/demos/evp",
    name: "umweltEventEVP",
    component: () =>
      import(
        /* webpackChunkName: "umwelteventevp" */ "@/views/UmweltDemo/EVP.vue"
      ),
  },
  {
    path: "/umwelt/demos/birthday",
    name: "umweltEventBirthday",
    component: () =>
      import(
        /* webpackChunkName: "umwelteventbirthday" */ "@/views/UmweltDemo/Birthday.vue"
      ),
  },
  {
    path: "/umwelt/demos/induction-video",
    name: "umweltEventInductionVideo",
    component: () =>
      import(
        /* webpackChunkName: "umwelteventinductionvideo" */ "@/views/UmweltDemo/InductionVideo.vue"
      ),
  },
  {
    path: "/umwelt/demos/nha",
    name: "umweltEventNHA",
    component: () =>
      import(
        /* webpackChunkName: "umwelteventnha" */ "@/views/UmweltDemo/NHA.vue"
      ),
  },
  {
    path: "/umwelt/demos/document-library",
    name: "umweltEventDocumentLibrary",
    component: () =>
      import(
        /* webpackChunkName: "umwelteventdocumentlibrary" */ "@/views/UmweltDemo/DocumentLibrary.vue"
      ),
  },
  {
    path: "/umwelt/demos/document/:id",
    name: "umweltEventDocumentViewer",
    component: () =>
      import(
        /* webpackChunkName: "umwelteventdocumentviewer" */ "@/views/UmweltDemo/DocumentViewer.vue"
      ),
  },
  {
    path: "/umwelt/demos/welcome360",
    name: "umweltEventWelcome360",
    component: () =>
      import(
        /* webpackChunkName: "umwelteventwelcome360" */ "@/views/UmweltDemo/Welcome360.vue"
      ),
  },
  {
    path: "/umwelt/demos/scavenger-hunt",
    name: "umweltEventScavenger",
    component: () =>
      import(
        /* webpackChunkName: "umwelteventscavengerhunt" */ "@/views/UmweltDemo/ScavengerHunt.vue"
      ),
  },
  {
    path: "/demos/ttal",
    name: "umweltEventTTAL",
    component: () =>
      import(
        /* webpackChunkName: "umwelteventttal" */ "@/views/UmweltDemo/TTAL.vue"
      ),
  },
  {
    path: "/umwelt/demos/gtm",
    name: "umweltEventGTM",
    component: () =>
      import(
        /* webpackChunkName: "umweltevengtm" */ "@/views/UmweltDemo/GTM.vue"
      ),
  },
  {
    path: "/umwelt/demos/pulse-one-week",
    name: "umweltEventPulseOneWeek",
    component: () =>
      import(
        /* webpackChunkName: "umweltevenpulseoneweek" */ "@/views/UmweltDemo/PulseOneWeek.vue"
      ),
  },
  {
    path: "/umwelt/demos/scratch-card",
    name: "umweltEventScratchCard",
    component: () =>
      import(
        /* webpackChunkName: "umwelteventscratchcard" */ "@/views/UmweltDemo/ScratchCard.vue"
      ),
  },
  {
    path: "/umwelt/demos/spin-the-wheel",
    name: "umweltEventSpinTheWheel",
    component: () =>
      import(
        /* webpackChunkName: "umwelteventspinthewheel" */ "@/views/UmweltDemo/SpinTheWheel.vue"
      ),
  },
  {
    path: "/umwelt/demos/jigsaw",
    name: "umweltEventJigsaw",
    component: () =>
      import(
        /* webpackChunkName: "umwelteventjigsaw" */ "@/views/UmweltDemo/Jigsaw.vue"
      ),
  },
];
