import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Toast, { POSITION } from "vue-toastification";
import LoadScript from "vue-plugin-load-script";
import "vue-toastification/dist/index.css";
import "./assets/css/tailwind.css";
import ApiService from "@/common/api.service";
import TokenService from "./common/token.service";
import "v-tooltip/dist/v-tooltip.css";
import VTooltip from "v-tooltip";
ApiService.init();

// Ensure we checked auth before each page load.
router.beforeEach((to, from, next) => {
  store.dispatch("addPageLoading");
  Promise.all([store.dispatch("checkAuth")]).then(next());
  if (TokenService.getToken("token") && TokenService.getToken("usersrc")) {
    TokenService.destroyToken("usersrc");
  }
});

router.afterEach(() => {
  setTimeout(() => store.dispatch("pageLoadingComplete"), 100);
});

const app = createApp(App);

app.use(VTooltip);

const options = {
  // You can set your default options here
  position: POSITION.TOP_CENTER,
};

app.use(Toast, options);
app.use(store);
app.use(router);
app.use(LoadScript);
app.mount("#app");
