import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";

export default {
  state: {
    profile: null,
    slugProfile: null
  },
  getters: {
    profile(state) {
      return state.profile;
    },
    slugProfile(state) {
      return state.slugProfile;
    },
  },
  mutations: {
    setProfile(state, payload) {
      state.profile = payload;
    },
    setSlugProfile(state, payload) {
      state.slugProfile = payload;
    },
  },
  actions: {
    getProfile({ commit }) {
      return new Promise((resolve, reject) => {
        ApiService.get(apiResource.user.getProfile)
          .then((response) => {
            resolve(response);
            commit("setProfile", response.data.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getSlugProfile({ commit }, payload) {
      return new Promise((resolve, reject) => {
        ApiService.get(apiResource.user.getProfile, payload)
          .then((response) => {
            resolve(response);
            commit("setSlugProfile", response.data.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    purgeProfileInfo({ commit }) {
      commit("setProfile", null);
      commit("setSlugProfile", null);
    }
  },
};
