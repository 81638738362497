export default [
    {
      path: "/events/shrm/:experience_link_id",
      name: "SHRMExpPage",
      meta: {
        requiresAuth: false,
      },
  
      component: () =>
        import(/* webpackChunkName: "shrmexppage" */ "@/views/Events/SHRM.vue"),
    },
  ];
  