export default [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Marketing/Home.vue"),
  },
  {
    path: "/features",
    name: "Features",
    component: () =>
      import(/* webpackChunkName: "features" */ "@/views/Marketing/Features.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Marketing/About.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Marketing/Contact.vue"),
  },
  {
    path: "/metaverse",
    name: "Metaverse",
    component: () =>
      import(/* webpackChunkName: "metaverse" */ "@/views/Marketing/Metaverse.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
  },
];
