import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import { createRouter, createWebHistory } from "vue-router";
import NotFound from "../views/NotFound.vue";
import store from "@/store";
import TokenService from "@/common/token.service";

import BaseRoutes from "./Base/BaseRoutes";
import ToolsRoutes from "./ToolsRoutes";
import CompanyRoutes from "./CompanyRoutes";
import EventsRoutes from "./EventsRoutes";
import ExperienceRoutes from "./ExperienceRoutes";
import DemoRoutes from "./DemoRoutes";
import UmweltDemoRoutes from "./UmweltDemoRoutes";

const routes = [
  ...BaseRoutes,
  ...ToolsRoutes,
  ...CompanyRoutes,
  ...EventsRoutes,
  ...ExperienceRoutes,
  ...DemoRoutes,
  ...UmweltDemoRoutes,
  {
    path: "/maintenance",
    name: "Maintenance",

    component: () =>
      import(/* webpackChunkName: "maintenance" */ "../views/Maintenance.vue"),
  },
  {
    path: "/:catchAll(.*)*",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.query.usersrc && !store.getters.userSrc) {
    TokenService.saveToken("usersrc", to.query.usersrc);
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (
      !store.getters.isAuthenticated &&
      (to.name != "Home" || to.name != "Features" || to.name != "About" || to.name != "Contact")
    ) {
      ApiService.post(apiResource.auth.checkCompanySlug, {
        slug: to.params.company,
      })
        .then((data) => {
          TokenService.saveToken("company", to.params.company);
          TokenService.saveToken("company_logo", data.data.data.logo);
          next({
            name: "Login",
            query: { redirect: to.fullPath },
          });
        })
        .catch(() => {
          next({ name: "NotFound", params: { catchAll: to.params.company } });
        });
    } else {
      if (to.params.company == store.getters.companySlug) {
        next();
      } else {
        next({ name: "NotFound", params: { catchAll: to.params.company } });
      }
    }
  } else {
    if (store.getters.isAuthenticated && to.name == "Login") {
      next({
        name: "UserHome",
        params: { company: store.getters.companySlug },
      });
    } else if (to.query.redirect && to.name == "Login") {
      const redirectPath = to.query.redirect.split("/");
      ApiService.post(apiResource.auth.checkCompanySlug, {
        slug: redirectPath[1],
      })
        .then((data) => {
          TokenService.saveToken("company", redirectPath[1]);
          TokenService.saveToken("company_logo", data.data.data.logo);
          next();
        })
        .catch(() => {
          next({ name: "NotFound", params: { catchAll: redirectPath[1] } });
        });
    } else {
      next(); // make sure to always call next()!
    }
  }
});

export default router;
