export default [
    {
      path: "/:company",
      name: "UserHome",
      meta: {
        requiresAuth: true,
      },
  
      component: () =>
        import(/* webpackChunkName: "userhome" */ "@/views/Home.vue"),
    },
    {
      path: "/:company/profile",
      name: "Profile",
      component: () =>
        import(/* webpackChunkName: "profile" */ "@/views/Profile/Simple.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/:company/:slug",
      name: "EmployeeProfile",
      component: () =>
        import(/* webpackChunkName: "employee-profile" */ "@/views/Profile/Employee.vue"),
      meta: {
        requiresAuth: true,
      },
    },
  ];
  